import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints, defaultTheme } from '../../styles';

const Wrapper = styled.div`
  position: relative;
  @media (min-width: 696px) {
    display: grid;
    grid-template-columns: 248px 400px;
  }
  @media (min-width: ${breakpoints.large}px) {
    grid-template-columns: 320px;
    grid-template-rows: 240px 300px;
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  height: 240px;
`;

const Copy = styled.div`
  background: #ffffff;
  padding: 2rem 2rem 3rem;
  font-family: var(--primary-font);
  height: 100%;
  h3 {
    font-weight: var(--primary-font-weight-bold);
    font-size: var(--primary-font-size-level-2);
    margin-bottom: 0.5rem;
  }
  h4 {
    font-weight: var(--primary-font-weight-regular);
    font-size: 14px;
    margin-bottom: 1.5rem;
  }
  p {
    font-weight: var(--primary-font-weight-regular);
    font-size: var(--primary-font-size-base);
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: ${defaultTheme.text};
  }
  @media (min-width: 696px) {
    padding: 2rem 1rem 2rem 2rem;
  }
  @media (min-width: ${breakpoints.large}px) {
    padding: 2rem;
  }
`;

const ProfileCard = ({ children, title, bio, name, facebook }) => {
  //
  return (
    <Wrapper>
      <ImageWrapper>{children}</ImageWrapper>
      <Copy>
        <h3>{name}</h3>
        <h4>{title}</h4>
        <p>{bio}</p>
      </Copy>
    </Wrapper>
  );
};

export default ProfileCard;

ProfileCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  facebook: PropTypes.string,
};

ProfileCard.defaultProps = {
  facebook: null,
};
