import React from 'react';
import styled from 'styled-components';
import { SectionHeader, SectionCopy } from '../../components';
import CabbageImage from './CabbageImage';
import { breakpoints, defaultTheme } from '../../styles';

const Section = styled.section`
  padding: 5rem 0 0 0;
  @media (min-width: ${breakpoints.medium}px) {
    position: relative;
    padding: 10rem 0;
  }
  @media (min-width: ${breakpoints.large}px) {
    position: relative;
    padding: 10rem 0 0;
  }
`;

const CenterLine = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    border-right: 1px ${defaultTheme.hr} solid;
    z-index: 1;
  }
`;

const Main = styled.div`
  position: relative;
  z-index: 2;
`;

const ImageWrapper = styled.div`
  height: 360px;
  @media (min-width: ${breakpoints.large}px) {
    height: 600px;
  }
`;

const Copy = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    width: 440px;
    margin: 2rem auto 2rem;
    padding: 3rem 2rem;
    box-shadow: var(--elevation-2);
  }
  @media (min-width: ${breakpoints.large}px) {
    padding: 3rem 3rem 4rem;
  }
`;

const LeftCopy = styled(Copy)`
  margin: 4rem 0 0;
  @media (min-width: ${breakpoints.medium}px) {
    background: ${defaultTheme.pink};
    h2,
    p {
      color: #ffffff;
    }
  }
`;

const RightCopy = styled(Copy)`
  margin: 4rem 0 0;
  padding-bottom: 4rem;
  @media (min-width: ${breakpoints.medium}px) {
    background: ${defaultTheme.blue};
    h2,
    p {
      color: #ffffff;
    }
  }
`;

const Container = styled.div`
  max-width: ${breakpoints.maxWidth}px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  @media (max-width: ${breakpoints.maxWidth}px) {
    padding: 0 2rem;
  }
  @media (min-width: ${breakpoints.large}px) {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    width: 100%;
    right: 0;
    left: 0;
  }
`;

const Solutions = () => {
  //
  return (
    <Section>
      <Main>
        <ImageWrapper>
          <CabbageImage />
        </ImageWrapper>
        <Container>
          <div>
            <LeftCopy>
              <SectionHeader modifiers={['small']}>
                <h2>Diverse Solutions</h2>
              </SectionHeader>
              <SectionCopy>
                VMSB represents quality products from Korea, Australia, Germany, USA, New Zealand and Spain. Some of
                which we represent exclusively.
              </SectionCopy>
              <SectionCopy>
                These products represent the disruptive technology of bringing natural inputs whilst producing high
                yield and caring for the environment.
              </SectionCopy>
            </LeftCopy>
          </div>
          <div>
            <RightCopy>
              <SectionHeader modifiers={['small']}>
                <h2>Extensive Network</h2>
              </SectionHeader>
              <SectionCopy>
                VMSB operates in this region through an exclusive dealer network, demanding commitment and strong focus.
              </SectionCopy>
            </RightCopy>
          </div>
        </Container>
      </Main>
      <CenterLine />
    </Section>
  );
};

export default Solutions;
