import React from 'react';
import styled from 'styled-components';
import { Container, SectionHeader } from '../../components';
import { breakpoints, defaultTheme } from '../../styles';
import DirectorImage from './DirectorImage';
import RaymondImage from './RaymondImage';
import LilyImage from './LilyImage';
import Card from './ProfileCard';

const Section = styled.section`
  border-top: 1px ${defaultTheme.hr} solid;
`;

const Block = styled.div`
  position: relative;
  padding: 5rem 0;
  @media (min-width: ${breakpoints.medium}px) {
    padding: 6.25rem 0;
  }
  @media (min-width: ${breakpoints.large}px) {
    padding: 10rem 0 14rem;
  }
`;

const ProfileGrid = styled.div`
  max-width: 320px;
  margin: 0 auto;
  display: grid;
  grid-row-gap: 4rem;
  @media (min-width: 696px) {
    max-width: 648px;
  }
  @media (min-width: ${breakpoints.large}px) {
    max-width: 1100px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const HeadingBlock = styled.div`
  text-align: center;
  margin-bottom: 5rem;
`;

const Team = () => {
  //
  return (
    <Section>
      <Container modifiers={['tightMargin']}>
        <Block>
          <HeadingBlock>
            <SectionHeader modifiers={['small']}>
              <h2>The Core Team</h2>
            </SectionHeader>
          </HeadingBlock>
          <ProfileGrid>
            <Card
              name="Chee King Chee"
              title="Director"
              bio="His experience as a planter with Golden Hope Plantations Berhad has brought valuable insights into the needs and requirements of plantations."
              facebook="https://www.facebook.com"
            >
              <DirectorImage />
            </Card>
            <Card
              name="Raymond Chee"
              title="Managing Director"
              bio="With a Masters Degree in Commerce - Marketing from the University of Strathclyde (Glasgow), Raymond brings work experience from KFC Holdings Bhd and RJ Reynolds Bhd."
              facebook="https://www.facebook.com"
            >
              <RaymondImage />
            </Card>
            <Card
              name="Lily Lee"
              title="Chief Finance Officer"
              bio="Besides keeping the company fiscally optimum, Lily plays a pivotal role in the day to day running of the company."
              facebook="https://www.facebook.com"
            >
              <LilyImage />
            </Card>
          </ProfileGrid>
        </Block>
      </Container>
    </Section>
  );
};

export default Team;
