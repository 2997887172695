import React from 'react';
import PropTypes from 'prop-types';
import { SEO } from '../components';
import Hero from '../page-components/about/Hero';
import Philosophy from '../page-components/about/Philosophy';
import Solutions from '../page-components/about/Solutions';
import Team from '../page-components/about/Team';

const About = ({ location: { pathname } }) => (
  <>
    <SEO title="About Venturemore" description="About Venturemore" />
    <Hero />
    <Philosophy />
    <Solutions />
    <Team />
  </>
);

export default About;

About.propTypes = {
  location: PropTypes.object.isRequired,
};
