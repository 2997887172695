import React from 'react';
import styled from 'styled-components';
import CustomerImage from './CustomerImage';
import { SectionHeader, SectionCopy, Container } from '../../components';
import { breakpoints, defaultTheme } from '../../styles';

const Section = styled.section``;

const Block = styled.div``;

const ImageWrapper = styled.div`
  height: 360px;
  margin-bottom: 2rem;
`;

const MainCopy = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: ${breakpoints.medium}px) {
    width: 400px;
    margin: 0 auto;
    background: ${defaultTheme.productImgBgHover};
    padding: 2rem 2rem 3rem;
    box-shadow: var(--elevation-2);
  }
  @media (min-width: ${breakpoints.large}px) {
    width: 480px;
    padding: 2rem 4rem 3rem;
  }
`;

const Philosophy = () => {
  //
  return (
    <Section>
      <Container>
        <Block>
          <MainCopy>
            <ImageWrapper>
              <CustomerImage />
            </ImageWrapper>
            <SectionHeader modifiers={['small']}>
              <h2>Our Philosophy</h2>
            </SectionHeader>
            <SectionCopy>
              Our client-first approach has established VMSB as a leading name in the field of hi-tech fertilizer and
              soil treatment.
            </SectionCopy>
          </MainCopy>
        </Block>
      </Container>
    </Section>
  );
};

export default Philosophy;
