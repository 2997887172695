import React from 'react';
import styled from 'styled-components';
import { SectionHeader, PrimaryButtonGatsby as Button, SectionCopy } from '../../components';
import HeroImage from './HeroImage';
import { breakpoints, defaultTheme } from '../../styles';

const Section = styled.section``;

const ImageWrapper = styled.div`
  height: 386px;
  @media (min-width: ${breakpoints.medium}px) {
    height: 480px;
  }
  @media (min-width: ${breakpoints.large}px) {
    height: 680px;
  }
`;

const Container = styled.div`
  max-width: ${breakpoints.maxWidth}px;
  margin: 0 auto;
  padding: 0 2rem;
  left: 0;
  right: 0;
`;

const HeroWrapper = styled.div`
  position: relative;
  padding-bottom: 5rem;
  @media (min-width: ${breakpoints.medium}px) {
    padding-bottom: 0;
  }
`;

const MainTitleWrapper = styled(Container)`
  margin-top: 5rem;
  @media (min-width: ${breakpoints.medium}px) {
    position: absolute;
    left: 0;
    top: 320px;
    margin-top: 0;
    h1 {
      color: #ffffff;
    }
  }
  @media (min-width: ${breakpoints.large}px) {
    position: absolute;
    top: 480px;
  }
`;

const CTAWrapper = styled(Container)`
  margin-top: 4rem;
  @media (min-width: ${breakpoints.medium}px) {
    position: absolute;
    margin-top: 0;
    top: 380px;
    right: 0;
  }
  @media (min-width: ${breakpoints.large}px) {
    position: absolute;
    top: 560px;
  }
`;

const MainCopyContainer = styled(Container)`
  @media (min-width: ${breakpoints.medium}px) {
    position: relative;
    padding: 8rem 0;
  }
`;

const MainCopy = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: ${breakpoints.medium}px) {
    width: 400px;
    margin: 0 auto;
    background: ${defaultTheme.productImgBg};
    padding: 2rem 2rem 3rem;
    box-shadow: var(--elevation-2);
  }
  @media (min-width: ${breakpoints.large}px) {
    width: 480px;
    padding: 2rem 4rem;
  }
`;

const HistorySectionHeader = styled(SectionHeader)`
  display: none;
  @media (min-width: ${breakpoints.medium}px) {
    display: block;
  }
`;

const CenterLine = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    border-right: 1px ${defaultTheme.hr} solid;
    z-index: 1;
  }
`;

const Hero = () => {
  //
  return (
    <Section>
      <HeroWrapper>
        <ImageWrapper>
          <HeroImage />
        </ImageWrapper>
        <MainTitleWrapper>
          <SectionHeader>
            <h1>The VentureMore story</h1>
          </SectionHeader>
        </MainTitleWrapper>
        <MainCopyContainer>
          <MainCopy>
            <HistorySectionHeader modifiers={['small']}>
              <h2>History</h2>
            </HistorySectionHeader>
            <SectionCopy>
              VENTUREMORE (M) SDN. BHD. (VMSB) began operation in 1991 as a general trading company in the agricultural
              sector. VMSB actively participates in identifying and providing products and innovative solutions to
              plantation groups, agriculture producers, landscapers, parks and golf courses.
            </SectionCopy>
          </MainCopy>
          <CenterLine />
        </MainCopyContainer>
        <CTAWrapper>
          <Button to="/products">Our products</Button>
        </CTAWrapper>
      </HeroWrapper>
    </Section>
  );
};

export default Hero;
